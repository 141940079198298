import React, { useContext } from 'react'
import Firebase from 'gatsby-plugin-firebase'

import { AuthProvider, AuthContext } from '../components/auth/auth'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const fourOhFour = () => {
  return (
    <AuthProvider>
      <Layout>
        <h1>404!</h1>
        <Link to='/'>Go home</Link>
      </Layout>
    </AuthProvider>
  )
}

export default fourOhFour